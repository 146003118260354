<template>
  <div class="thirdTest">
    <div>授权成功:{{ oData }}</div>
    <!-- <div v-else>授权失败</div> -->
  </div>
</template>

<script>
import { thirdTestUrl } from "./api.js";
import { getQueryString } from "@/utils/utils.js";
export default {
  name: "thirdTest",
  data() {
    return {
      oData: null,
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    communityId() {
      return this.$store.state.communityId;
    },
  },
  async mounted() {
    this.getAuthUser();
  },
  methods: {
    getAuthUser() {
      let ticket = getQueryString("ticket");
      let appId = getQueryString("appId");
      let params = {
        ticket: ticket,
        appId: appId,
      };
      this.$axios.post(`${thirdTestUrl}`, params).then((res) => {
        if (res.code === 200) {
          if (res.data) {
            this.oData = JSON.stringify(res.data);
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.thirdTest {
}
</style>
